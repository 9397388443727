<template>
  <div class="relative size-full">
    <ClientOnly>
      <FullCalendar ref="fullCalendar" :options="calendarOptions">
        <template #dayHeaderContent="{ date, isToday, view }: DayHeaderContentArg">
          <div class="flex flex-col gap-1 text-black-70">
            <span
              class="text-body-2 uppercase"
              :class="{ 'text-black-40': view.type === CALENDAR_VIEWS.MONTH, 'text-primary-100': isToday }"
              >{{ format(date, 'ccc') }}</span
            >
            <div
              v-if="view.type !== CALENDAR_VIEWS.MONTH"
              class="cursor-pointer rounded-sm p-2"
              :class="{ 'bg-primary-10': isToday }"
              @click="gotoDay(date)"
            >
              <h4>{{ format(date, 'd') }}</h4>
            </div>
          </div>
        </template>
        <!-- Month only -->
        <template #dayCellContent="{ date, isToday, view }: DayCellContentArg">
          <div id="day-cell" class="flex flex-col gap-2 text-black-70">
            <span
              id="weekday"
              class="text-body-2 text-center uppercase"
              :class="{ 'text-black-40': view.type === CALENDAR_VIEWS.MONTH, 'text-primary-100': isToday }"
              >{{ format(date, 'ccc') }}</span
            >
            <div
              :class="{ 'bg-primary-10': isToday }"
              class="max-w-[33px] cursor-pointer rounded-sm p-2 text-center text-black-70"
              @click="view.type === CALENDAR_VIEWS.MONTH ? null : gotoDay(date)"
            >
              <span class="text-subhead-4">{{ format(date, 'd') }}</span>
            </div>
          </div>
        </template>
        <template #slotLabelContent="{ date }: SlotLabelContentArg">
          <span class="text-body-2 uppercase text-black-70">{{ format(date, 'h a') }}</span>
        </template>
        <template #eventContent="{ event, view }: EventContentArg">
          <div
            class="flex size-full flex-row items-center gap-1 rounded-md px-2 py-1"
            :class="{ 'cursor-default': !event.extendedProps.type || !event.extendedProps.status }"
          >
            <div v-if="isShortEvent(event)" class="w-full truncate text-black-100">
              <span v-if="event.title" class="text-caption truncate"> {{ event.title }}, </span>
              <span v-if="event.start && event.end" class="text-caption-2">
                {{ format(event.start as Date, 'h:mm') }}-{{ format(event.end as Date, 'p') }}
              </span>
            </div>
            <div v-else-if="view.type === CALENDAR_VIEWS.MONTH" class="w-full truncate text-black-100">
              <span v-if="event.start && event.end" class="text-caption-2">
                {{ format(event.start as Date, 'p') }},
              </span>
              <span v-if="event.title" class="text-caption truncate"> {{ event.title }} </span>
            </div>
            <div v-else class="flex w-full max-w-[calc(100%_-_24px)] flex-col justify-between text-black-100">
              <span class="text-caption truncate">
                {{ event.title }}
              </span>
              <span v-if="event.start && event.end" class="text-caption-2">
                {{ format(event.start as Date, 'h:mm') }}-{{ format(event.end as Date, 'p') }}
              </span>
            </div>
            <UiInputCheckbox
              v-if="!readOnly && event.extendedProps.type && event.extendedProps.status"
              :id="`activity_status_${event.id}`"
              :model-value="event.extendedProps.status?.code === CALENDAR_ACTIVITY_STATUSES.DONE"
              :name="event.title"
              round
              :read-only="managerMode || event.extendedProps.status?.code === CALENDAR_ACTIVITY_STATUSES.DONE"
              border-color-class="border-black-30"
              @click.stop
              @update:model-value="updateActivityStatus(event)"
            />
          </div>
        </template>
        <template #nowIndicatorContent>
          <div class="relative">
            <div class="h-px w-full bg-additional-3-100"></div>
            <UiIcon name="dot" class="absolute top-[-6px] !h-3 !w-3 text-additional-3-100"></UiIcon>
          </div>
        </template>
        <template #moreLinkContent="{ num, view }: MoreLinkContentArg">
          <UiTagPrimary
            v-show="view.type === CALENDAR_VIEWS.MONTH"
            id="more_link"
            class="text-caption !h-5 !px-2 !py-1 text-primary-100"
            icon-color="text-primary-100"
            ghost
          >
            {{ num }} more
          </UiTagPrimary>
          <div v-show="view.type !== CALENDAR_VIEWS.MONTH" id="more_link_wrap">+{{ num }}</div>
        </template>
      </FullCalendar>
    </ClientOnly>
    <UiLoader v-if="loading" :class="['absolute z-10', hideDayHeaders ? 'top-0' : 'top-[72px]']" />
  </div>
</template>

<script setup lang="ts">
import uniqBy from 'lodash/uniqBy'
import { format, intervalToDuration, subHours } from 'date-fns'
import type {
  CalendarOptions,
  DayCellContentArg,
  DayHeaderContentArg,
  EventContentArg,
  MoreLinkContentArg,
  SlotLabelContentArg,
} from '@fullcalendar/core'
import { EventImpl } from '@fullcalendar/core/internal'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {
  CALENDAR_VIEWS,
  CALENDAR_ACTIVITY_STATUSES,
  CALENDAR_ACTIVITY_TYPES,
  CALENDAR_ACTIVITY_TYPES_COLORS_MAP,
} from '@/constants'
import type { ActivitiesFilters, Activity, LibraryItem } from '@/types'
import { useUiStore } from '~/store/ui'
import { POPUPS } from '@/components/dynamic/maps'

const uiStore = useUiStore()

const emits = defineEmits([
  'updated:view',
  'updated:dates',
  'selected:date',
  'clicked:event',
  'updated:title',
  'dbclicked:event',
  'updated:count',
])

type Props = {
  filters?: ActivitiesFilters
  initialView?: string
  hideDayHeaders?: boolean
  initialDate?: Date | string
  readOnly?: Boolean
  localEvents?: Activity[]
  managerMode?: Boolean
  eventColor?: string
  getActivities?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  filters: () => ({
    type_ids: [],
    status_ids: undefined,
    user_id: undefined,
  }),
  initialView: CALENDAR_VIEWS.WEEK,
  initialDate: () => new Date(),
  readOnly: () => false, // hides the checkboxes
  localEvents: () => [],
  managerMode: () => false, // read only but shows the checkboxes
  eventColor: () => '',
  getActivities: true,
})

onNuxtReady(async () => {
  eventStatuses.value = await useCalendarActivityStatuses()
})

const fullCalendar = ref()
const loading = ref(false)
const eventStatuses = ref<LibraryItem[]>([])

const getActivities = async (
  info: { start: Date; end: Date },
  successCallback: Function,
  failureCallback: Function
) => {
  loading.value = true
  const params = useParseFilters(props.filters, null, ['status_ids'])
  params.start = [params.start?.[0] || info.start.toISOString(), params.start?.[1] || info.end.toISOString()]
  delete params.orderBy
  try {
    const { data } = await useGetCalendarTimelineActivities(params)
    const events = uniqBy([...props.localEvents, ...data], 'id').map((e: Activity) => {
      e.backgroundColor = props.eventColor || CALENDAR_ACTIVITY_TYPES_COLORS_MAP.get(e.type?.code)?.background

      //  Otherwise events in month view don't have right background color
      e.display = 'block'
      e.start = new Date(e.start)
      e.end = new Date(e.end)
      e.allDay = e.type?.code === CALENDAR_ACTIVITY_TYPES.ALL_DAY
      return e
    })

    if (events.length) {
      const earliestEventByHour = events.reduce((r, o) => (o.start.getHours() < r.start.getHours() ? o : r))
      fullCalendar.value?.getApi().scrollToTime(format(subHours(new Date(earliestEventByHour.start), 2), 'HH:mm'))
    }

    successCallback(events)
    emits('updated:count', events.length)
    loading.value = false
  } catch (err) {
    loading.value = false
    failureCallback(err)
  }
}

const calendarOptions = ref<CalendarOptions>({
  plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
  initialView: props.initialView,
  events: props.getActivities ? getActivities : [],
  selectAllow: (slot) => {
    const duration = intervalToDuration({
      start: slot.start,
      end: slot.end,
    })

    return duration.hours === 1
  },
  eventClick({ event, el }) {
    if (!event.extendedProps.type || !event.extendedProps.status) return
    el.style.backgroundColor =
      props.eventColor || (CALENDAR_ACTIVITY_TYPES_COLORS_MAP.get(event.extendedProps.type?.code)?.pressed as string)

    emits('clicked:event', {
      id: event.id,
      title: event.title,
      start: event.start,
      end: event.end,
      ...event.extendedProps,
    })
  },
  eventDidMount({ el, event }) {
    el.id = event.id
  },
  dateClick: ({ date }: { date: Date }) => {
    emits('selected:date', date)
  },
  slotLaneDidMount: ({ el, view }) => {
    if (view.type === CALENDAR_VIEWS.WEEK) {
      // for cell hovering
      const cellHoverElementsContainer = document.createElement('div')
      cellHoverElementsContainer.className = 'cell-hover-elements-container'
      for (let i = 0; i < 7; i++) {
        cellHoverElementsContainer.appendChild(document.createElement('div'))
      }

      el.appendChild(cellHoverElementsContainer)
    }
  },
  eventMouseEnter: function ({ el, event }) {
    el.style.backgroundColor =
      props.eventColor || (CALENDAR_ACTIVITY_TYPES_COLORS_MAP.get(event.extendedProps.type?.code)?.hovered as string)
  },
  eventMouseLeave: ({ el, event }) => {
    el.style.backgroundColor =
      props.eventColor || (CALENDAR_ACTIVITY_TYPES_COLORS_MAP.get(event.extendedProps.type?.code)?.background as string)
  },
  datesSet: ({ start, end, view }) => {
    emits('updated:dates', { start, end })
    emits('updated:title', view.title)
  },
  initialDate: new Date(props.initialDate),
  eventMinHeight: 24,
  dayMaxEventRows: 4,
  eventMaxStack: 4,
  headerToolbar: false,
  stickyHeaderDates: true,
  height: '100%',
  allDaySlot: true,
  allDayText: ' ',
  nowIndicator: true,
  views: {
    timeGridWeek: {
      selectable: true,
      slotDuration: '01:00:00',
      eventMaxStack: 1,
      dayMaxEventRows: 2,
    },
    timeGridDay: {
      dayHeaders: !props.hideDayHeaders,
      selectable: true,
      slotDuration: '01:00:00',
      eventMaxStack: 1,
      dayMaxEventRows: 2,
    },
    dayGridMonth: {},
  },
  eventColor: props.eventColor,
})

// Needed so full calendar renders correctly when transitioning page
onNuxtReady(() => {
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'))
    showHideBoxShadow()
    fullCalendar.value?.getApi().refetchEvents()
  }, 500)
})

const showHideBoxShadow = () => {
  const shadow = '0 20px 16px -16px rgba(13, 15, 21, 0.08)'
  // Show box-shadow on load
  const tableHeaders = document.querySelectorAll('tbody tr')[0] as HTMLElement
  if (!tableHeaders) return
  tableHeaders.style.boxShadow = shadow
  tableHeaders.style.transition = 'all cubic-bezier(0.4, 0, 0.2, 1) 200ms'
  // Show or hide by scroll position
  const scrollableElement = document.getElementsByClassName('fc-scroller fc-scroller-liquid-absolute')[0]

  scrollableElement?.addEventListener('scroll', (event) => {
    if (!(event.target as HTMLElement)?.scrollTop) {
      tableHeaders.style.boxShadow = ''
    } else {
      tableHeaders.style.boxShadow = shadow
    }
  })
}

const updateActivityStatus = async (event: EventImpl & Activity) => {
  if (
    event.extendedProps.type?.code === CALENDAR_ACTIVITY_TYPES.MEETING &&
    !event.extendedProps.meeting_outcome_resolution_id
  ) {
    uiStore.showPopup(
      POPUPS.MEETING_OUTCOME,
      { activity: { ...event.extendedProps, id: event.id, start: event.start, end: event.end } },
      {
        input: () => getEvents(),
        close: () => {
          nextTick(() => {
            const checkbox = document.getElementById(`activity_status_${event.id}`)?.getElementsByTagName('input')[0]
            if (!checkbox) return
            checkbox.checked = false
          })
        },
      }
    )
  } else {
    const statusCode = CALENDAR_ACTIVITY_STATUSES.DONE

    const status = eventStatuses.value.find((s) => s.code === statusCode)
    if (!status) return

    event.setExtendedProp('status', status)
    await useUpdateCalendarActivityStatus(Number(event.id), status.id)
  }
}

const isShortEvent = (event: EventImpl) => {
  if (!event.start || !event.end) return
  const duration = intervalToDuration({
    start: event.start,
    end: event.end,
  })

  if (duration.hours) return false

  return duration.minutes <= 58
}

const prev = () => {
  fullCalendar.value.getApi().prev()
}

const next = () => {
  fullCalendar.value.getApi().next()
}

const gotoToday = () => {
  fullCalendar.value.getApi().gotoDate(new Date())
}

const gotoDay = (date: Date) => {
  fullCalendar.value.getApi().changeView(CALENDAR_VIEWS.DAY)
  fullCalendar.value.getApi().gotoDate(date)
  emits('updated:view', CALENDAR_VIEWS.DAY)
}

const gotoDate = (date: Date) => {
  fullCalendar.value.getApi().gotoDate(date)
}

const changeView = (view: string) => {
  fullCalendar.value.getApi().changeView(view)
  showHideBoxShadow()
}

const getEvents = () => {
  fullCalendar.value.getApi().refetchEvents()
}

const updateStatusEvent = (id: number, status: LibraryItem) => {
  const event = fullCalendar.value.getApi().getEventById(id)
  if (!event) return

  event.setExtendedProp('status', status)
}

defineExpose({
  prev,
  next,
  gotoToday,
  gotoDay,
  gotoDate,
  changeView,
  getEvents,
  updateStatusEvent,
})
</script>

<style scoped></style>
